import React from "react";
import "./Footer.css";
const Footer = () => {
	return (
		<div className="footer">
			<div className="footer-links">
				<a href="#" className="footer-single-link">
					Contáctanos
				</a>
				<a href="#" className="footer-single-link">
					Política de Privacidad
				</a>
				<a href="#" className="footer-single-link">
					Desarrollado por Codium Labs
				</a>
			</div>
			<p className="footer-main-paragraph">
				Todos los derechos reservados Jean Up 2024
			</p>
		</div>
	);
};

export default Footer;
