import React from "react";
import "./WhatsappButton.css";
const WhatsAppButton = () => {
	const phoneNumber = "593979225747";
	const message =
		"Hola, me gustaría obtener más información sobre sus productos.";

	const whatsappUrl = `https://api.whatsapp.com/send?phone=${phoneNumber}&text=${encodeURIComponent(
		message
	)}`;

	return (
		<a
			href={whatsappUrl}
			target="_blank"
			rel="noopener noreferrer"
		>
			<img
				className="wa-button"
				src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/whatsappIcon2.svg?updatedAt=1718161524958"
			/>
		</a>
	);
};

export default WhatsAppButton;
