export const styles = {
  container: {
    width: 700,
    right: 150,
    alignSelf: "center",
    position: "fixed",
    display: "flex",
    flexDirection: "column",
  },
  itemContainer: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    width: "600px",
    margin: "50px",
  },
  sectionTitle: {
    alignSelf: "center",
    margin: 0,
    fontSize: "2.6em",
  },
  title: {
    fontSize: "2.4em",
  },
  description: {
    margin: "0",
    fontSize: "1.8em",
  },
};
