import { getWhatsAppLink } from "../utils";

export const storesData = [
  {
    name: "Quito",
    stores: [
      {
        city: "C.C Quicentro Sur",
        phone: "0998328040",
        mapsLink: "https://maps.app.goo.gl/nHpY1AfQqLL4euYx8?g_st=iw",
        whatsappLink: getWhatsAppLink("593998328040"),
        dayTitle1: "Lunes - Sábado",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Domingo",
        dayHour2: "10h00 a 20h00",
      },
      {
        city: "C.C El Recreo",
        phone: "0995185417",
        mapsLink: "https://maps.app.goo.gl/bsT5yvQsg8gjDdgP6?g_st=iw",
        whatsappLink: getWhatsAppLink("593995185417"),
        dayTitle1: "Lunes - Sábado",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Domingo",
        dayHour2: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Quito",
    stores: [
      {
        city: "C.C San Luis Shopping",
        phone: "0987355864",
        mapsLink: "https://maps.app.goo.gl/8WzzGP8DZhrMEUrZA?g_st=iw",
        whatsappLink: getWhatsAppLink("593987355864"),
        dayTitle1: "Lunes - Sábado",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Domingo",
        dayHour2: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Guayaquil",
    stores: [
      {
        city: "C.C Mall del Sur",
        phone: "0963308997",
        additionalPhone: "(04) 217-8534",
        mapsLink: "https://g.co/kgs/3wQoVDb",
        whatsappLink: getWhatsAppLink("593963308997"),
        dayTitle1: "Lunes - Sábado",
        dayHour1: "10h00 a 21h00",
        dayTitle2: "Domingo",
        dayHour2: "10h00 a 21h00",
      },
      {
        city: "C.C Ríocentro Norte",
        phone: "",
        additionalPhone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Domingo - Jueves",
        dayHour1: "10h00 a 21h00",
        dayTitle2: "Viernes - Sábado",
        dayHour2: "10h00 a 22h00",
      },
    ],
  },
  {
    name: "Guayaquil",
    stores: [
      {
        city: "C.C Ríocentro Sur",
        phone: "",
        additionalPhone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Sábado",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Domingo",
        dayHour2: "10h00 a 20h00",
      },
      {
        city: "C.C Garzocentro 2000",
        phone: "",
        additionalPhone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Domingo",
        dayHour1: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Santo Domingo",
    stores: [
      {
        city: "Av. 29 de Mayo y Tsáchila",
        phone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Domingo",
        dayHour1: "10h00 a 21h00",
      },
      {
        city: "Av. Rio Lelia y Los Ceibos",
        phone: "0997078968",
        mapsLink: "https://maps.app.goo.gl/RAbBBcLEagVc8ZeW6?g_st=iw",
        whatsappLink: getWhatsAppLink("593997078968"),
        dayTitle1: "Lunes - Domingo",
        dayHour1: "09h0 a 19h00",
      },
    ],
  },
  {
    name: "Riobamba",
    stores: [
      {
        city: "C.C. Paseo Shopping Riobamba",
        phone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Domingo",
        dayHour1: "10h00 a 21h00",
      },
    ],
  },
  {
    name: "Guaranda",
    stores: [
      {
        city: "Av. 7 de Mayo y García Moreno",
        phone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Domingo",
        dayHour1: "09h00 a 20h00",
      },
    ],
  },
  {
    name: "Loja",
    stores: [
      {
        city: "C.C. Don Daniel Mall",
        phone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Jueves",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Viernes – Sábado",
        dayHour2: "10h00 a 21h00",
        dayTitle3: "Domingo",
        dayHour3: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Cuenca",
    stores: [
      {
        city: "C.C Mall del Río",
        phone: "0992505542",
        mapsLink: "https://maps.app.goo.gl/MjkHNWNes8zEJuhL9?g_st=iw",
        whatsappLink: getWhatsAppLink("593992505542"),
        dayTitle1: "Lunes - Jueves",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Viernes - Sábado",
        dayHour2: "10h00 a 21h00",
        dayTitle3: "Domingo",
        dayHour3: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Ambato",
    stores: [
      {
        city: "Ambato Centro Av. Sucre y Mera Esquina",
        phone: "0998772975",
        mapsLink: "https://maps.app.goo.gl/JWnuygeCubx3ZBrg9?g_st=iw",
        whatsappLink: getWhatsAppLink("593998772975"),
        dayTitle1: "Lunes - Jueves",
        dayHour1: "10h00 a 18h30",
        dayTitle2: "Sábado",
        dayHour2: "09h30 a 21h00",
        dayTitle3: "Domingo",
        dayHour3: "Cerrado",
      },
      {
        city: "C.C. Paseo Shopping",
        phone: "",
        mapsLink: "",
        whatsappLink: "",
        dayTitle1: "Lunes - Domingo",
        dayHour1: "10h00 a 21h00",
      },
    ],
  },
  {
    name: "Ibarra",
    stores: [
      {
        city: "C.C Laguna Mall",
        phone: "0979212030",
        mapsLink: "https://maps.app.goo.gl/hu1JJJHEnMAAeGJDA?g_st=iw",
        whatsappLink: getWhatsAppLink("593979212030"),
        dayTitle1: "Lunes - Jueves",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Viernes - Sábado",
        dayHour2: "10h00 a 20h30",
        dayTitle3: "Domingo",
        dayHour3: "10h00 a 20h00",
      },
    ],
  },
  {
    name: "Latacunga",
    stores: [
      {
        city: "C.C Malteria Plaza",
        phone: "0984171798",
        mapsLink: "https://maps.app.goo.gl/b5XmGPpnpR5fSAQdA?g_st=iw",
        whatsappLink: getWhatsAppLink("593984171798"),
        dayTitle1: "Lunes - Jueves",
        dayHour1: "10h00 a 20h00",
        dayTitle2: "Viernes - Sábado",
        dayHour2: "10h00 a 21h00",
        dayTitle3: "Domingo",
        dayHour3: "10h00 a 20h00",
      },
    ],
  },
];
