import React from "react";
import { StoresCarousel } from "../../components/Carousel/StoresCarousel";
import "./FourthView.css";

export default function FourthView() {
  return (
    <div className="section">
      <div className="fourth-view-container">
        <div className="fourth-view-layer1">
          <StoresCarousel />
        </div>
        <div className="fourth-view-layer2">
          <img
            className="fourth-view-layer2-img"
            src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/modelWomen3.jpg?updatedAt=1718337755984"
          />
        </div>
      </div>
    </div>
  );
}
