import React, { useState } from "react";
import "./Navbar.css";
import { Sidebar } from "../MobileMenu/Sidebar";
const NavBar = props => {
  return (
    <>
      <nav className="navbar-main">
        <a className="navbar-logo">
          <img
            src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/nav_logo.svg?updatedAt=1718080872930"
            alt="Jean Up Logo"
          />
        </a>

        <ul className="navbar-menu">
          <li>
            <a href="#firstPage">Inicio</a>
          </li>
          <li>
            <div className="manual">
              <a href="#secondPage">Sobre Nosotros</a>
            </div>
          </li>
          <li>
            <a href="#thirdPage">Productos</a>
          </li>
          <li>
            <a href="#fourthPage">Nuestras Tiendas</a>
          </li>
          <li>
            <a href="#fifthPage">Contáctanos</a>
          </li>
        </ul>
      </nav>
      <Sidebar />
    </>
  );
};

export default NavBar;
