import React, { useState, useEffect } from "react";
import { ProductsCarousel } from "../../components/Carousel/ProductsCarousel";
import "./ThirdView.css";
export default function ThirdView() {
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < 990
	);

	const updateMedia = () => {
		setIsMobile(window.innerWidth < 990);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () =>
			window.removeEventListener("resize", updateMedia);
	}, []);

	return (
		<div className="section">
			<div className="third-view-container">
				<div className="third-view-layer1">
					<ProductsCarousel />
				</div>
				<div className="third-view-layer2">
					<img
						className="third-view-layer2-img"
						src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/products-model.jpg?updatedAt=1718241245227"
					/>
				</div>
			</div>
		</div>
	);
}
