import React, { useState } from "react";
import ReactPlayer from "react-player";
import { GoMute, GoUnmute } from "react-icons/go";
import "./SecondView.css";

export default function SecondView({ index }) {
  const [isMutedVideo, setIsMutedVideo] = useState(true);

  const toggleSoundVideo = () => setIsMutedVideo(prevState => !prevState);

  return (
    <div className="section">
      <div className="second-view-container">
        <div className="video-wrapper">
          <div className="button-mute" onClick={toggleSoundVideo}>
            {isMutedVideo ? (
              <GoMute className="button-mute-icon" />
            ) : (
              <GoUnmute className="button-mute-icon" />
            )}
          </div>
          <ReactPlayer
            playIcon
            playsinline
            height={"80%"}
            width={"95%"}
            url="https://ik.imagekit.io/j2ipdn2v1/JeanUp/videoModelo1.mp4?updatedAt=1717976612322"
            playing={index === 1}
            loop={true}
            muted={isMutedVideo}
            volume={0.2}
          />
        </div>
      </div>
    </div>
  );
}
