import React from "react";
import { styles } from "../../styles/ContactView";
import "./SocialIcon.css";
const SocialIcon = props => {
	return (
		<a href={props.href} target="blank">
			<img className="social-icon" src={props.src} alt="" />
		</a>
	);
};

export default SocialIcon;
