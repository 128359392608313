import React, { useState } from "react";
import ReactFullpage from "@fullpage/react-fullpage";
import {
  FirstView,
  FourthView,
  SecondView,
  ThirdView,
  ContactView,
} from "./views";
import WhatsappButton from "./components/WhatsappButton/WhatsappButton.js";
import NavBar from "./components/Navbar/Navbar.js";
import "./index.css";

const anchors = [
  "firstPage",
  "secondPage",
  "thirdPage",
  "fourthPage",
  "fifthPage",
];

const App = () => {
  const [index, setIndex] = useState(0);
  return (
    <>
      <NavBar />
      <WhatsappButton />
      <ReactFullpage
        anchors={anchors}
        navigation
        navigationTooltips={anchors}
        navigat
        sectionsColor={["#7fff00", "#00ffff", "#29ab87"]}
        onLeave={(origin, destination, direction) => {
          setIndex(destination.index);
        }}
        render={({ state, fullpageApi }) => (
          <div>
            <FirstView />
            <SecondView index={index} />
            <ThirdView />
            <FourthView />
            <ContactView />
          </div>
        )}
      />
    </>
  );
};

export default App;
