export const styles = {
	container: {
		width: 700,
		right: 100,
		alignSelf: "center",
		position: "fixed",
		display: "flex",
		flexDirection: "column",
	},
	itemContainer: {
		alignItems: "center",
		display: "flex",
		flexDirection: "column",
		width: "600px",
		// margin: "50px",
	},
	sectionTitle: {
		alignSelf: "center",
		// margin: 0,
		fontSize: "2.6em",
		fontWeight: "600",
	},
	title: {
		fontSize: "2.4em",
		fontWeight: "500",
	},
	description: {
		fontWeight: "400",
		margin: "0",
		fontSize: "1.8em",
	},
};
