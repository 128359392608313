export const getWhatsAppLink = (
  number = "593981140888",
  message = "Hola, me gustaría obtener más información sobre sus productos."
) => {
  return `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(
    message
  )}`;
};

export const getMapsLink = (
  number = "593981140888",
  message = "Hola, me gustaría obtener más información sobre sus productos."
) => {
  return `https://api.whatsapp.com/send?phone=${number}&text=${encodeURIComponent(
    message
  )}`;
};
