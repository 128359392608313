import React, { useState } from "react";
import Carousel1 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styles } from "../../styles/StoresCarousel";
import "../../styles/StoresCarousel.css";
import { getMapsLink, getWhatsAppLink } from "../utils";
import { storesData } from "./storesData";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 5,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 1,
  },
  tablet: {
    breakpoint: { max: 1024, min: 480 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 480, min: 0 },
    items: 1,
  },
};

export const StoresCarousel = ({ data }) => {
  return (
    <div style={styles.container}>
      <Carousel1
        responsive={responsive}
        infinite={true}
        autoPlaySpeed={4000}
        autoPlay={true}
        removeArrowOnDeviceType={["tablet", "mobile", "desktop"]}
      >
        {storesData.map((item, index) => {
          return (
            <CarouselItem
              city={item}
              title={item.title}
              description={item.description}
            />
          );
        })}
      </Carousel1>
    </div>
  );
};

function CarouselItem({
  city = {
    name: "Quito",
    stores: [storeItemData, storeItemData],
  },
}) {
  return (
    <div className="containerItem">
      <h2 className="cityTitle">{city.name}</h2>
      {city.stores.map(item => (
        <StoreItem store={item} />
      ))}
    </div>
  );
}

const StoreItem = ({
  store = {
    phone: "0988298929",
    city: "C.C Quicentro Sur",
    dayTitle1: "Lunes - Sábado",
    dayHour1: "10h00 a 20h00",
    dayTitle2: "Domingo",
    dayHour2: "10h00 a 20h00",
    whatsappLink: "",
    mapsLink: "",
  },
}) => (
  <>
    <Spacer size={"5%"} />
    <h2 className="addressTitle">{store.city}</h2>
    <Spacer size={"3%"} />
    <div className="containerInfo">
      <div className="containerSchedule">
        <h2 className="daysText">{store.dayTitle1}</h2>
        <Spacer size={10} />
        <h2 className="hoursText">{store.dayHour1}</h2>
        <Spacer size={15} />
        <div className="containerLinks">
          <a className="linkItem" target="_blank" href={store.whatsappLink}>
            <img
              className="linkIcon"
              src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/whatsappIcon2.svg?updatedAt=1718161524958"
            />
            <Spacer size={5} direction="row" />
            <h2 className="linkText">WhatsApp</h2>
          </a>
          <Spacer size={5} />
          <a className="linkItem" target="_blank" href={store.mapsLink}>
            <img
              className="linkIcon"
              src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/mapsIcon1.svg?updatedAt=1718254500524"
            />
            <Spacer size={5} direction="row" />
            <h2 className="linkText">Ubicación</h2>
          </a>
        </div>
      </div>
      <Spacer size={150} direction="row" />
      <div className="containerSchedule">
        <h2 className="daysText">{store.dayTitle2}</h2>
        <Spacer size={10} />
        <h2 className="hoursText">{store.dayHour2}</h2>
        <Spacer size={15} />
        <div className="containerPhone">
          <h2 className="phoneText">{store.phone}</h2>
        </div>
      </div>
    </div>
  </>
);

const Spacer = ({ size, direction = "column" }) => (
  <div
    style={{
      height: direction === "column" ? size : 0,
      width: direction === "row" ? size : 0,
      flexDirection: direction,
    }}
  />
);

const storeItemData = {
  phone: "0988298929",
  city: "C.C Quicentro Sur",
  dayTitle1: "Lunes - Sábado",
  dayHour1: "10h00 a 20h00",
  dayTitle2: "Domingo",
  dayHour2: "10h00 a 20h00",
  whatsappLink: getWhatsAppLink(),
  mapsLink: getMapsLink(),
};

const products = [
  {
    title: "Pantalón Skinny",
    description:
      "Con un ajuste ceñido, se adaptan perfectamente a la forma de tus piernas, proporcionando un look moderno y estilizado. Disponibles en tallas 30-42 para caballeros. Disponibles exclusivamente en denim.",
  },
  {
    title: "Pantalón Slim Fit",
    description:
      "Este estilo proporciona un ajuste que no es ni demasiado pegado ni demasiado ancho, ideal para un look cómodo y contemporáneo. Disponibles en tallas 30-42 para caballeros. Disponible en denim y gabardina.",
  },
  {
    title: "Pantalón Regular",
    description:
      "ofreciendo funcionalidad y estilo. Con múltiples bolsillos, son ideales para un look casual y práctico. Disponibles en tallas 30-42 para caballeros. Disponible en denim y gabardina.",
  },
  {
    title: "Pantalón Cargo",
    description:
      "Con un ajuste ceñido, se adaptan perfectamente a la forma de tus piernas, proporcionando un look moderno y estilizado. Disponibles en tallas 30-42 para caballeros. disponibles exclusivamente en denim.",
  },
  {
    title: "Complementos para Hombre",
    description:
      "Además de nuestros pantalones de alta calidad, ofrecemos una variedad de complementos para hombre que te ayudarán a completar cualquier outfit. Contamos con camisas polo, camisas formales, camisetas casuales, correas elegantes y chompas cómodas.",
  },
];
