import React, { useState, useEffect } from "react";
import Carousel1 from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { styles } from "../../styles/ProductsCarousel";

const responsive = {
	superLargeDesktop: {
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 1,
	},
	tablet: {
		breakpoint: { max: 1024, min: 480 },
		items: 2,
	},
	mobile: {
		breakpoint: { max: 480, min: 0 },
		items: 1,
	},
};

const products = [
	{
		title: "Pantalón Skinny",
		description:
			"Con un ajuste ceñido, se adaptan perfectamente a la forma de tus piernas, proporcionando un look moderno y estilizado. Disponibles en tallas 30-42 para caballeros. Disponibles exclusivamente en denim.",
	},
	{
		title: "Pantalón Slim Fit",
		description:
			"Este estilo proporciona un ajuste que no es ni demasiado pegado ni demasiado ancho, ideal para un look cómodo y contemporáneo. Disponibles en tallas 30-42 para caballeros. Disponible en denim y gabardina.",
	},
	{
		title: "Pantalón Regular",
		description:
			"ofreciendo funcionalidad y estilo. Con múltiples bolsillos, son ideales para un look casual y práctico. Disponibles en tallas 30-42 para caballeros. Disponible en denim y gabardina.",
	},
	{
		title: "Pantalón Cargo",
		description:
			"Con un ajuste ceñido, se adaptan perfectamente a la forma de tus piernas, proporcionando un look moderno y estilizado. Disponibles en tallas 30-42 para caballeros. disponibles exclusivamente en denim.",
	},
	{
		title: "Complementos para Hombre",
		description:
			"Además de nuestros pantalones de alta calidad, ofrecemos una variedad de complementos para hombre que te ayudarán a completar cualquier outfit. Contamos con camisas polo, camisas formales, camisetas casuales, correas elegantes y chompas cómodas.",
	},
];

function CarouselItem({ title, description }) {
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < 990
	);

	const updateMedia = () => {
		setIsMobile(window.innerWidth < 990);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () =>
			window.removeEventListener("resize", updateMedia);
	}, []);
	const mobileTitle = {
		fontSize: "2.8em",
	};
	const mobileDescription = {
		margin: "0",
		fontSize: "2.6em",
		color: "#00000099",
	};
	return (
		<div style={styles.itemContainer}>
			<h2 style={isMobile ? mobileTitle : styles.title}>
				{title}
			</h2>
			<p
				style={
					isMobile ? mobileDescription : styles.description
				}
			>
				{description}
			</p>
		</div>
	);
}

export const ProductsCarousel = ({ data }) => {
	const [isMobile, setIsMobile] = useState(
		window.innerWidth < 990
	);

	const updateMedia = () => {
		setIsMobile(window.innerWidth < 990);
	};

	useEffect(() => {
		window.addEventListener("resize", updateMedia);
		return () =>
			window.removeEventListener("resize", updateMedia);
	}, []);

	const mobileSectionTitle = {
		alignSelf: "center",
		margin: 0,
		fontSize: "3.8em",
		position: "relative",
	};
	return (
		<div style={styles.container}>
			<h1
				style={
					isMobile
						? mobileSectionTitle
						: styles.sectionTitle
				}
			>
				Nuestros Productos
			</h1>
			<Carousel1
				responsive={responsive}
				infinite={true}
				autoPlaySpeed={5000}
				autoPlay={true}
				removeArrowOnDeviceType={[
					"tablet",
					"mobile",
					"desktop",
				]}
			>
				{products.map((item, index) => {
					return (
						<CarouselItem
							title={item.title}
							description={item.description}
						/>
					);
				})}
			</Carousel1>
		</div>
	);
};
