import React, { useState } from "react";
import "./Sidebar.css";
export const Sidebar = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  return (
    <>
      <div className="menu-btn" onClick={toggleMenu}>
        <img
          src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/menubtn.svg?updatedAt=1718316128685"
          alt=""
        />
      </div>
      <div className={isMenuOpen ? "sidebar-menu-container" : "sidebar-closed"}>
        <nav className="sidebar-menu">
          <ul>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#firstPage">Inicio</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <div className="manual">
                <a href="#secondPage">Sobre Nosotros</a>
              </div>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#thirdPage">Productos</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#fourthPage">Nuestras Tiendas</a>
            </li>
            <li onClick={() => setIsMenuOpen(false)}>
              <a href="#fifthPage">Contáctanos</a>
            </li>
          </ul>
        </nav>
      </div>
      {isMenuOpen && (
        <div
          className={"sidebar-menu-container-close-part"}
          onClick={toggleMenu}
        />
      )}
    </>
  );
};
