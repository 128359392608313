import "./FirstView.css";
import "../Second.css";
export default function FirstView() {
  return (
    <div className="section">
      <div className="first-view-container">
        <div className="layer1">
          <p className="layer1-text">
            Diseños exclusivos que combinan moda, <br /> comodidad y durabilidad
          </p>
        </div>

        <div className="layer2">
          <img
            className="layer2-image"
            src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/storeImage2.jpg?updatedAt=1718940058926"
            alt="Jean Up Model"
          />
        </div>
        <div className="layer3">
          <img
            className="layer3-logo"
            src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/nav_logo.svg?updatedAt=1718080872930"
            alt="Jean Up Logo"
          />
        </div>
      </div>
    </div>
  );
}
