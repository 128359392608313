import React from "react";
import Footer from "../../components/Footer/Footer";
import SocialIcon from "../../components/SocialIcon/SocialIcon";
import "./ContactView.css";
export default function ContactView() {
	return (
		<div className="section">
			<div className="contact-view-container">
				<div>
					<img
						src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/sloganLogo.svg?updatedAt=1718163763905"
						alt=""
						className="contact-view-main-logo"
					/>
				</div>
				<div>
					<h2 className="contact-view-heading">
						Dirección Principal
					</h2>
					<p className="contact-view-paragraph">
						Av. Sucre y Mera Esquina
						<br />
						Ambato, Ecuador
					</p>
					<h2 className="contact-view-heading">email</h2>
					<p className="contact-view-paragraph">
						<a
							href="mailto:info@jean-up.com"
							className="contact-view-link"
						>
							jeanupweb@hotmail.com
						</a>
					</p>
					<h2 className="contact-view-heading">Teléfono</h2>
					<p className="contact-view-paragraph">
						<a
							href="tel:+593979225747"
							className="contact-view-link"
						>
							(+593) 979225747
						</a>
					</p>
					<h2 className="contact-view-heading">
						Nuestras Redes
					</h2>
					<div className="contact-view-social-icons">
						<SocialIcon
							href="https://www.instagram.com/jeanupoficial?igsh=ajJ6OGd3eGdhYjJi"
							src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/instagram.png?updatedAt=1718159742204"
						/>
						<SocialIcon
							href="https://www.facebook.com/jeanup?mibextid=ZbWKwL"
							src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/facebook.png?updatedAt=1718159742134"
						/>
						<SocialIcon
							href="https://www.tiktok.com/@jeanupof1?_t=8mtcECEkuVp&_r=1"
							src="https://ik.imagekit.io/j2ipdn2v1/JeanUp/tiktok.png?updatedAt=1718161364532"
						/>
					</div>
				</div>
				<Footer />
			</div>
		</div>
	);
}
